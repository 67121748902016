import PropertyTypeItemService from '../service/property_type_item'
import Loader from './loader';
import toast from './toast';


export const state = {
    state: {
        property_type_items: [],
        property_type_item: {},
    },
};

export const getters = {
    getPropertyTypeItem(state) {
        return state.property_type_items
    },
}

export const mutations = {
    PROPERTY_TYPE_ITEMS(state, data) {
        state.property_type_items = data
    },
};

export const actions = {
    // eslint-disable-next-line no-unused-vars
    allPropertyTypeItem({ commit, actions }, query) {
        return PropertyTypeItemService.index(query)
            .then(response => {
                commit('PROPERTY_TYPE_ITEMS', response.data)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },

    // eslint-disable-next-line no-unused-vars
    createPropertyTypeItem({ commit, actions }, data) {
        Loader.open()
        return PropertyTypeItemService.store(data)
            .then(response => {
                Loader.close()
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                Loader.close()
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    updatePropertyTypeItem({ commit, actions }, data) {
        return PropertyTypeItemService.update(data.id, data)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    deletePropertyTypeItem({ commit, actions }, id) {
        return PropertyTypeItemService.delete(id)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
}