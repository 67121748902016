import { axios } from './index'

export default {

  index(params) {
    return axios
      .get('statistics', {
        params,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },
}
