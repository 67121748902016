import { axios } from './index'

export default {

  index(params) {
    return axios
      .get('parking-items', {
        params,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },

  store(data) {
    return axios
      .post('parking-items', data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  update(id, data) {
    return axios
      .put(`parking-items/${id}`, data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  delete(id) {
    return axios
      .delete(`parking-items/${id}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
}
