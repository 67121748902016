import ConfigurationService from '../service/configuration'
import Loader from './loader';
import toast from './toast';


export const state = {
    state: {
        configurations: [],
        configuration: {},
    },
};

export const getters = {
    getConfigurations(state) {
        return state.configurations
    },
    showConfigurationData(state) {
        return state.configuration
    },
}

export const mutations = {
    GET_CONFIGURATION(state, data) {
        state.configurations = data
    },
    SHOW_CONFIGURATION(state, data) {
        state.configuration = data
    }
};

export const actions = {
    // eslint-disable-next-line no-unused-vars
    allConfiguration({ commit, actions }, query) {
        return ConfigurationService.index(query)
            .then(response => {
                commit('GET_CONFIGURATION', response.data)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    showConfiguration({ commit }, id) {
        return ConfigurationService.show(id)
            .then(response => {
                commit('SHOW_CONFIGURATION', response.data)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },

    // eslint-disable-next-line no-unused-vars
    createConfiguration({ commit, actions }, data) {
        Loader.open()
        return ConfigurationService.store(data)
            .then(response => {
                Loader.close()
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                Loader.close()
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    updateConfiguration({ commit, actions }, data) {
        return ConfigurationService.update(data.id, data)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    deleteConfiguration({ commit, actions }, id) {
        return ConfigurationService.delete(id)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    markAsCancel({ commit, actions }, data) {
        return ConfigurationService.markAsCancel(data.id, data)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },

}