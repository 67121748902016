import StatisticsService from '../service/statistics'
import toast from './toast';


export const state = {
    state: {
        statistics: [],
    },
};

export const getters = {
    getStatistics(state) {
        return state.payments
    },
}

export const mutations = {
    GET_STATISTICS(state, data) {
        state.statistics = data
    },
};

export const actions = {
    // eslint-disable-next-line no-unused-vars
    allStatistics({ commit, actions }, query) {
        return StatisticsService.index(query)
            .then(response => {
                commit('GET_STATISTICS', response.data)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },

}