import FAQsService from '../service/faqs'
import Loader from './loader';
import toast from './toast';


export const state = {
    state: {
        faqs: [],
        faq: {},
    },
};

export const getters = {
    getFaqs(state) {
        return state.faqs
    },
}

export const mutations = {
    GET_FAQS(state, data) {
        state.faqs = data
    },
};

export const actions = {
    // eslint-disable-next-line no-unused-vars
    allFaqs({ commit }, query) {
        return FAQsService.index(query)
            .then(response => {
                commit('GET_FAQS', response.data)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },

    // eslint-disable-next-line no-unused-vars
    createFaqs({ commit, actions }, data) {
        Loader.open()
        return FAQsService.store(data)
            .then(response => {
                Loader.close()
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                Loader.close()
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    updateFaqs({ commit, actions }, data) {
        return FAQsService.update(data.id, data)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    deleteFaqs({ commit, actions }, id) {
        return FAQsService.delete(id)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },

}