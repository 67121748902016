import AmenityItemService from '../service/amenity_item'
import Loader from './loader';
import toast from './toast';


export const state = {
    state: {
        amenity_items: [],
        amenity_item: {},
    },
};

export const getters = {
    getAmenityItem(state) {
        return state.amenity_items
    },
}

export const mutations = {
    GET_AMENITY_ITEMS(state, data) {
        state.amenity_items = data
    },
};

export const actions = {
    // eslint-disable-next-line no-unused-vars
    allAmenityItem({ commit, actions }, query) {
        return AmenityItemService.index(query)
            .then(response => {
                commit('GET_AMENITY_ITEMS', response.data)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },

    // eslint-disable-next-line no-unused-vars
    createAmenityItem({ commit, actions }, data) {
        Loader.open()
        return AmenityItemService.store(data)
            .then(response => {
                Loader.close()
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                Loader.close()
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    updateAmenityItem({ commit, actions }, data) {
        return AmenityItemService.update(data.id, data)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    deleteAmenityItem({ commit, actions }, id) {
        return AmenityItemService.delete(id)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars

}
