import ExpensesCategoryService from '../service/expenses_category'
import Loader from './loader';
import toast from './toast';


export const state = {
    state: {
        expenses_categories: [],
        expenses_category: {},
    },
};

export const getters = {
    getExpenses(state) {
        return state.expenses
    },
}

export const mutations = {
    GET_EXPENSES_CATEGORY(state, data) {
        state.expenses = data
    },
};

export const actions = {
    // eslint-disable-next-line no-unused-vars
    allExpensesCategory({ commit, actions }, query) {
        return ExpensesCategoryService.index(query)
            .then(response => {
                commit('GET_EXPENSES_CATEGORY', response.data)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },

    // eslint-disable-next-line no-unused-vars
    createExpensesCategory({ commit, actions }, data) {
        Loader.open()
        return ExpensesCategoryService.store(data)
            .then(response => {
                Loader.close()
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                Loader.close()
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    updateExpensesCategory({ commit, actions }, data) {
        return ExpensesCategoryService.update(data.id, data)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    deleteExpensesCategory({ commit, actions }, id) {
        return ExpensesCategoryService.delete(id)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    markAsCancel({ commit, actions }, data) {
        return ExpensesCategoryService.markAsCancel(data.id, data)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },

}