import { axios } from './index'

export default {

  index(params) {
    return axios
      .get('page-contents', {
        params,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },

  getPageOfContent(params) {
    return axios
      .get('get-page-content', {
        params,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },


  store(data) {
    return axios
      .post('page-contents', data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },

  update(id, data) {
    return axios
      .put(`page-contents/${id}`, data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  delete(id) {
    return axios
      .delete(`page-contents/${id}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
}
