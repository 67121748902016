import PropertyService from '../service/property'
import Loader from './loader';
import toast from './toast';


export const state = {
    state: {
        properties: [],
    },
};

export const getters = {
    getProperties(state) {
        return state.properties
    },
}

export const mutations = {
    GET_PROPERTIES(state, data) {
        state.properties = data
    },
    SHOW_PROPERTY(state, data){
        state.property = data
    }
};

export const actions = {
    /* eslint-disable */
    allProperty({ commit, actions }, query) {
        return PropertyService.index(query)
            .then(response => {
                commit('GET_PROPERTIES', response.data)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    allPropertyByLeaseStartDateAndLeaseEndDate({ commit, actions }, query) {
        return PropertyService.getPropertyByLeaseStartDateAndLeaseEndDate(query)
            .then(response => {
                commit('GET_PROPERTIES', response.data)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    allPropertyByAvailabilityDate({ commit, actions }, query) {
        return PropertyService.getPropertyByAvailabilityDate(query)
            .then(response => {
                commit('GET_PROPERTIES', response.data)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    allPropertyByPropertyOwnerWithStatus({ commit, actions }, query) {
        return PropertyService.getPropertyByPropertyOwnerWithStatus(query)
            .then(response => {
                commit('GET_PROPERTIES', response.data)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    allPropertyByStatus({ commit, actions }, query) {
        return PropertyService.getPropertyByStatus(query)
            .then(response => {
                commit('GET_PROPERTIES', response.data)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    allPropertyByPropertyType({ commit, actions }, query) {
        return PropertyService.getPropertyByPropertyType(query)
            .then(response => {
                commit('GET_PROPERTIES', response.data)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    showProperty({ commit }, id) {
        return PropertyService.show(id)
            .then(response => {
                commit('SHOW_PROPERTY', response.data)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },

    // eslint-disable-next-line no-unused-vars
    createProperty({ commit, actions }, data) {
        Loader.open()
        return PropertyService.store(data)
            .then(response => {
                Loader.close()
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                Loader.close()
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    updateProperty({ commit, actions }, data) {
        return PropertyService.update(data.id, data)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    deleteProperty({ commit, actions }, id) {
        return PropertyService.delete(id)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
        // eslint-disable-next-line no-unused-vars
        deletePropertyGallery({ commit, actions }, id) {
            return PropertyService.deletePropertyGallery(id)
                .then(response => {
                    toast(response.message)
                    return Promise.resolve(response)
                })
                .catch(error => {
                    toast(error.response.data.message, 'error')
                    return Promise.reject(error.response.data.message)
                })
        },

        // eslint-disable-next-line no-unused-vars
        createPropertyGallery({ commit, actions }, data) {
            Loader.open()
            return PropertyService.storePropertyGallery(data)
                .then(response => {
                    Loader.close()
                    toast(response.message.message)
                    return Promise.resolve(response)
                })
                .catch(error => {
                    Loader.close()
                    toast(error.response.data.message, 'error')
                    return Promise.reject(error.response.data.message)
                })
        },

                // eslint-disable-next-line no-unused-vars
                orderPropertyGallery({ commit, actions }, data) {
                    // Loader.open()
                    return PropertyService.orderPropertyGallery(data)
                        .then(response => {
                            // Loader.close()
                            toast("Gallery Order Successfully")
                            return Promise.resolve(response)
                        })
                        .catch(error => {
                            // Loader.close()
                            // toast(error.response.data.message, 'error')
                            return Promise.reject(error.response.data.message)
                        })
                },


    // eslint-disable-next-line no-unused-vars
    deleteAmenity({ commit, actions }, id) {
        return PropertyService.deleteAmenity(id)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    createAmenity({ commit, actions }, data) {
        return PropertyService.storeAmenity(data)
            .then(response => {
                toast(response.message.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    updateAmenity({ commit, actions }, data) {
        return PropertyService.updateAmenity(data.id, data)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    deleteParkingType({ commit, actions }, id) {
        return PropertyService.deleteParkingType(id)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    createParkingType({ commit, actions }, data) {
        return PropertyService.storeParkingType(data)
            .then(response => {
                toast(response.message.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    updateParkingType({ commit, actions }, data) {
        return PropertyService.updateParkingType(data.id, data)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    deletePropertyType({ commit, actions }, id) {
        return PropertyService.deletePropertyType(id)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    createPropertyType({ commit, actions }, data) {
        return PropertyService.storePropertyType(data)
            .then(response => {
                toast(response.message.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    updatePropertyType({ commit, actions }, data) {
        return PropertyService.updatePropertyType(data.id, data)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    deleteUtility({ commit, actions }, id) {
        return PropertyService.deleteUtility(id)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    createUtility({ commit, actions }, data) {
        return PropertyService.storeUtility(data)
            .then(response => {
                toast(response.message.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    updateUtility({ commit, actions }, data) {
        return PropertyService.updateUtility(data.id, data)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
}