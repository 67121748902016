import ExpensesService from '../service/expenses'
import Loader from './loader';
import toast from './toast';


export const state = {
    state: {
        expenses: [],
        expense: {},
    },
};

export const getters = {
    getExpenses(state) {
        return state.expenses
    },
}

export const mutations = {
    GET_EXPENSES(state, data) {
        state.expenses = data
    },
};

export const actions = {
    // eslint-disable-next-line no-unused-vars
    allExpenses({ commit, actions }, query) {
        return ExpensesService.index(query)
            .then(response => {
                commit('GET_EXPENSES', response.data)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },

        // eslint-disable-next-line no-unused-vars
        allExpensesWithDateRange({ commit, actions }, query) {
            return ExpensesService.getAllExpensesWithDateRange(query)
                .then(response => {
                    commit('GET_EXPENSES', response.data)
                    return Promise.resolve(response)
                })
                .catch(error => {
                    toast(error.response.data.message, 'error')
                    return Promise.reject(error.response.data.message)
                })
        },
        // eslint-disable-next-line no-unused-vars
        allExpensesWithMonthYear({ commit, actions }, query) {
            return ExpensesService.getAllExpensesWithMonthYear(query)
                .then(response => {
                    commit('GET_EXPENSES', response.data)
                    return Promise.resolve(response)
                })
                .catch(error => {
                    toast(error.response.data.message, 'error')
                    return Promise.reject(error.response.data.message)
                })
        },
    
        // eslint-disable-next-line no-unused-vars
        allExpensesForProperty({ commit, actions }, query) {
            return ExpensesService.getAllExpensesForProperty(query)
                .then(response => {
                    commit('GET_EXPENSES', response.data)
                    return Promise.resolve(response)
                })
                .catch(error => {
                    toast(error.response.data.message, 'error')
                    return Promise.reject(error.response.data.message)
                })
        },
        // eslint-disable-next-line no-unused-vars
        allExpensesForVendor({ commit, actions }, query) {
            return ExpensesService.getAllExpensesForVendor(query)
                .then(response => {
                    commit('GET_EXPENSES', response.data)
                    return Promise.resolve(response)
                })
                .catch(error => {
                    toast(error.response.data.message, 'error')
                    return Promise.reject(error.response.data.message)
                })
        },
    

    // eslint-disable-next-line no-unused-vars
    createExpenses({ commit, actions }, data) {
        Loader.open()
        return ExpensesService.store(data)
            .then(response => {
                Loader.close()
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                Loader.close()
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    updateExpenses({ commit, actions }, data) {
        return ExpensesService.update(data.id, data)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    deleteExpenses({ commit, actions }, id) {
        return ExpensesService.delete(id)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    markAsCancel({ commit, actions }, data) {
        return ExpensesService.markAsCancel(data.id, data)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },

}