import { axios } from './index'

export default {

  index(params) {
    return axios
      .get('payments', {
        params,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },

  store(data) {
    return axios
      .post('payments', data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  update(id, data) {
    return axios
      .put(`payments/${id}`, data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  delete(id) {
    return axios
      .delete(`payments/${id}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },
  markAsCancel(id, data) {
    return axios
      .put(`payments/${id}`, data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  },

  getAllPaymentWithDateRange(params) {
    return axios
      .get('all-payment-with-date-range', {
        params,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },
  getAllPaymentWithMonthYear(params) {
    return axios
      .get('get-all-payment-with-month-year', {
        params,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },
  getAllPaymentForProperty(params) {
    return axios
      .get('get-all-payment-for-property', {
        params,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },
  getAllPaymentForTenant(params) {
    return axios
      .get('get-all-payment-for-tenant', {
        params,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },
}
