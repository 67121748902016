import Toastify from 'toastify-js';

function toast(message, colorText = "success"){
   let messageType = colorText;
   let color = "linear-gradient(to right, #00b09b, #96c93d)"
   if(messageType === 'info'){
        color = "#0dcaf0"
   } else if(messageType === 'error'){
    color = "#db2b19"
   } else if(messageType === 'warning'){
    color = "#ffc107"
   } 
    Toastify({
        text: message,
        gravity: "top", // `top` or `bottom`
        position: "center", // `left`, `center` or `right`
        duration: 3000,
        style: {
            background: color,
          }
        }).showToast();   
}

export default toast;