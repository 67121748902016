import PaymentService from '../service/payment'
import Loader from './loader';
import toast from './toast';


export const state = {
    state: {
        payments: [],
        payment: {},
    },
};

export const getters = {
    getPayments(state) {
        return state.payments
    },
}

export const mutations = {
    GET_PAYMENTS(state, data) {
        state.payments = data
    },
};

export const actions = {
    // eslint-disable-next-line no-unused-vars
    allPayment({ commit, actions }, query) {
        return PaymentService.index(query)
            .then(response => {
                commit('GET_PAYMENTS', response.data)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },

        // eslint-disable-next-line no-unused-vars
    allPaymentWithDateRange({ commit, actions }, query) {
        return PaymentService.getAllPaymentWithDateRange(query)
            .then(response => {
                commit('GET_PAYMENTS', response.data)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    allPaymentWithMonthYear({ commit, actions }, query) {
        return PaymentService.getAllPaymentWithMonthYear(query)
            .then(response => {
                commit('GET_PAYMENTS', response.data)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },

    // eslint-disable-next-line no-unused-vars
    allPaymentForProperty({ commit, actions }, query) {
        return PaymentService.getAllPaymentForProperty(query)
            .then(response => {
                commit('GET_PAYMENTS', response.data)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },

    // eslint-disable-next-line no-unused-vars
    allPaymentForTenant({ commit, actions }, query) {
        return PaymentService.getAllPaymentForTenant(query)
            .then(response => {
                commit('GET_PAYMENTS', response.data)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },

    // eslint-disable-next-line no-unused-vars
    createPayment({ commit, actions }, data) {
        Loader.open()
        return PaymentService.store(data)
            .then(response => {
                Loader.close()
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                Loader.close()
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    updatePayment({ commit, actions }, data) {
        return PaymentService.update(data.id, data)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    deletePayment({ commit, actions }, id) {
        return PaymentService.delete(id)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
    markAsCancel({ commit, actions }, data) {
        return PaymentService.markAsCancel(data.id, data)
            .then(response => {
                toast(response.message)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },

}