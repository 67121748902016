import MonthlyReportService from '../service/monthly_report'
import Loader from './loader';
import toast from './toast';


export const state = {
    state: {
        monthly_reports: [],
    },
};

export const getters = {
    getMonthlyReport(state) {
        return state.monthly_reports
    },
}

export const mutations = {
    GET_MONTHLY_REPORTS(state, data) {
        state.statistics = data
    },
};

export const actions = {
    /*eslint-disable*/ 
    allMonthlyReports({ commit, actions }, query) {
        return MonthlyReportService.index(query)
            .then(response => {
                commit('GET_MONTHLY_REPORTS', response.data)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    allMonthlyReportByDateRange({ commit, actions }, query) {
        return MonthlyReportService.getMonthlyReportByDateRange(query)
            .then(response => {
                commit('GET_MONTHLY_REPORTS', response.data)
                return Promise.resolve(response)
            })
            .catch(error => {
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
    // eslint-disable-next-line no-unused-vars
        createMonthlyReports({ commit, actions }, data) {
            Loader.open()
            return MonthlyReportService.store(data)
                .then(response => {
                    Loader.close()
                    toast(response.message)
                    return Promise.resolve(response)
                })
                .catch(error => {
                    Loader.close()
                    toast(error.response.data.error, 'error')
                    return Promise.reject(error.response.data.message)
                })
        },

}